.page-text {
  background-color: $gray;
  padding: (190 * 100vw / 1920) 0 0;
  position: relative;
  @include media($w1024) {
    padding: 125px 0 0;
  }
  &__inner {
    padding: 0 0 (190 * 100vw / 1920);
    position: relative;
    @include media($w1024) {
      padding: 0 0 60px;
    }
    @include media($w560) {
      padding: 0 0 50px;
    }
  }
  &__copy {
    margin: 0 0 (60 * 100vw / 1920);
    @include media($w1024) {
      margin: 0 0 42px;
    }
  }
}
