.btn {
	font-size: (17 * 100vw / 1920);
	font-weight: 500;
	line-height: (44 * 100vw / 1920);
	text-transform: uppercase;
	letter-spacing: 0.2em;
	color: $lead;
	margin: 0;
	padding: 0 (90 * 100vw / 1920) 0 0 !important;
	display: inline-block;
	position: relative;
	@include media($w1024) {
		font-size: 16px;
		line-height: 30px;
		letter-spacing: 0.1em;
		padding: 0 55px 0 0 !important;
	}
	&:before,
	&:after {
		@include translateY;
		@include transition;
		content: '';
		display: block;
		position: absolute;
	}
	&:before {
		background-image: url(../images/btn--arrow.svg);
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
		width: (48 * 100vw / 1920);
		height: (44 * 100vw / 1920);
		z-index: 50;
		right: (18 * 100vw / 1920);
		@include media($w1024) {
			width: 30px;
			height: 30px;
			right: 15px;
		}
	}
	&:after {
		border-radius: 50%;
		background-color: $green;
		width: (44 * 100vw / 1920);
		height: (44 * 100vw / 1920);
		z-index: 25;
		right: 0;
		@include media($w1024) {
			width: 30px;
			height: 30px;
		}
	}
	&:hover {
		color: $lead;
		&:before {
			right: (8 * 100vw / 1920);
			@include media($w1024) {
				right: 10px;
			}
		}
	}
	&--without {
		color: $white;
		&:before {
			background-image: url(../images/btn--arrow--green.svg);
		}
		&:after {
			display: none;
		}
		&:hover {
			color: $white;
		}
	}
	&--dark {
		color: $white;
		&:before {
			background-image: url(../images/btn--arrow--dark.svg);
		}
		&:hover {
			color: $white;
		}
	}
	&--white {
		color: $white;
		&:before {
			background-image: url(../images/btn--arrow--white.svg);
		}
		&:hover {
			color: $white;
		}
	}
}
