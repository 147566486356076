.fleet {
  position: relative;
  &:before {
    content: '';
    background-image: url(../images/fleet--addon.svg);
    background-size: cover;
    background-position: top right;
    width: (240 * 100vw / 1920);
    height: (240 * 100vw / 1920);
    position: absolute;
    top: (-90 * 100vw / 1920);
    left: (-120 * 100vw / 1920);
    @include media($w1024) {
      width: 100px;
      height: 100px;
      top: -30px;
      left: -50px;
  	}
  }
  &__inner {
    padding: (160 * 100vw / 1920) 0;
    display: flex;
    @include media($w1024) {
      padding: 50px 0;
  	}
  }
  &__copy {
    flex: 0 0 50%;
    @include media($w1024) {
      flex: 0 0 60%;
    }
    @include media($w768) {
      flex: 0 0 100%;
    }
    h2 {
    	margin: 0 0 (30 * 100vw / 1920);
      @include media($w1024) {
        margin: 0 0 30px;
    	}
    }
    h4 {
      width: 100%;
      max-width: (480 * 100vw / 1920);
      font-weight: 400;
      margin: 0 0 (50 * 100vw / 1920);
      @include media($w1024) {
        max-width: calc(100% - 30px);
        margin: 0 0 30px;
    	}
      @include media($w768) {
        max-width: 100%;
      }
    }
  }
  &__image {
    background-size: cover;
    background-position: center center;
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    @include media($w1024) {
      width: 40%;
    }
    @include media($w768) {
      display: none;
    }
  }
  &__man {
    @include translateX;
    width: (428 * 100vw / 1920);
    position: absolute;
    bottom: 0;
    left: calc(50% + (75 * 100vw / 1920));
    @include media($w1024) {
      display: none;
    }
  }
}
