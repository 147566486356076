div {
  &.wpforms-container-full {
    margin: (-18 * 100vw / 1920) 0 0 !important;
    .wpforms-form {
      .wpforms-submit-container {
        padding: 0 !important;
      }
      button[type="submit"] {
        font-family: 'Futura PT' !important;
        font-size: (17 * 100vw / 1920) !important;
      	font-weight: 500 !important;
      	line-height: (44 * 100vw / 1920) !important;
      	text-transform: uppercase !important;
      	letter-spacing: 0.2em !important;
      	color: #392f23 !important;
      	margin: 0 0 0 auto !important;
      	padding: 0 (90 * 100vw / 1920) 0 0 !important;
      	display: block !important;
      	position: relative !important;
      	&:before,
      	&:after {
      		content: '' !important;
      		display: block !important;
          transform: translateY(-50%) !important;
          transition: all 0.4s ease !important;
      		position: absolute !important;
          top: 50% !important;
      	}
      	&:before {
      		background-image: url(../images/btn--arrow.svg) !important;
      		background-size: contain !important;
      		background-position: center center !important;
      		background-repeat: no-repeat !important;
      		width: (48 * 100vw / 1920) !important;
      		height: (44 * 100vw / 1920) !important;
      		z-index: 50 !important;
      		right: (18 * 100vw / 1920) !important;
      	}
      	&:after {
      		border-radius: 50% !important;
      		background-color: #6ece4c !important;
      		width: (44 * 100vw / 1920) !important;
      		height: (44 * 100vw / 1920) !important;
      		z-index: 25 !important;
      		right: 0 !important;
      	}
      	&:hover {
      		color: #392f23 !important;
      		&:before {
      			right: (8 * 100vw / 1920) !important;
      		}
      	}
      }
      .wpforms-field {
        margin: 0 0 (20 * 100vw / 1920) !important;
        padding: 0 !important;
        position: relative !important;
        &.wpforms-field-textarea {
          margin: 0 0 (40 * 100vw / 1920) !important;
        }
        &.wpforms-field-checkbox {
          margin: 0 0 (55 * 100vw / 1920) !important;
          & > .wpforms-field-label {
            margin: 0 0 (30 * 100vw / 1920) !important;
          }
          & > ul {
            li {
              justify-content: space-between !important;
              display: flex !important;
              & > input {
                margin: (3 * 100vw / 1920) 0 0 !important;
              }
              & > label {
                flex: 0 0 calc(100% - (25 * 100vw / 1920)) !important;
              }
            }
          }
        }
      }
    }
  }
}

.wpforms-confirmation-container-full {
  border: 0 !important;
  background-color: transparent !important;
  margin: 0 !important;
  padding: (5 * 100vw / 1920) 0 0 !important;
  p {
    &:first-child {
      font-size: (55 * 100vw / 1920) !important;
      line-height: (65 * 100vw / 1920) !important;
      font-weight: 500 !important;
      margin: 0 0 (25 * 100vw / 1920) !important;
    }
  }
}


@media (max-width: 1024px) {
  div {
    &.wpforms-container-full {
      margin: 0 !important;
      .wpforms-form {
        button[type="submit"] {
          font-size: 16px !important;
      		line-height: 30px !important;
      		letter-spacing: 0.1em !important;
          margin: 0 !important;
      		padding: 0 55px 0 0 !important;
        	&:before {
            width: 30px !important;
      			height: 30px !important;
      			right: 15px !important;
        	}
        	&:after {
            width: 30px !important;
      			height: 30px !important;
        	}
        	&:hover {
        		&:before {
        			right: 10px !important;
        		}
        	}
        }
        .wpforms-field {
          margin: 0 0 15px !important;
          &.wpforms-field-textarea {
            margin: 0 0 40px !important;
          }
          &.wpforms-field-checkbox {
            margin: 0 0 30px !important;
            & > .wpforms-field-label {
              margin: 0 0 20px !important;
            }
            & > ul {
              li {
                & > input {
                  margin: 3px 0 0 !important;
                }
                & > label {
                  flex: 0 0 calc(100% - 25px) !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .wpforms-confirmation-container-full {
    border-top: 1px solid rgba(0,0,0,0.125) !important;
    margin: 15px 0 0 !important;
    padding: 25px 0 0 !important;
    p {
      &:first-child {
        font-size: 36px !important;
        line-height: 42px !important;
        margin: 0 0 18px !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .wpforms-confirmation-container-full {
    p {
      &:first-child {
        font-size: 30px !important;
        line-height: 36px !important;
      }
    }
  }
}

@media (max-width: 560px) {
  .wpforms-confirmation-container-full {
    p {
      &:first-child {
        font-size: 24px !important;
        line-height: 30px !important;
      }
    }
  }
}
