.company {
  background-color: $white;
  padding: (130 * 100vw / 1920) 0 (160 * 100vw / 1920);
  position: relative;
  @include media($w1024) {
    padding: 50px 0;
  }
  &__inner {
    display: flex;
    @include media($w1024) {
      flex-wrap: wrap;
    }
  }
  &__copy {
    flex: 0 0 41.5%;
    padding: 0 (130 * 100vw / 1920) 0 0;
    @include media($w1024) {
      flex: 0 0 100%;
      padding: 0 0 30px;
    }
    h4 {
      font-weight: 400;
      margin: 0 0 (65 * 100vw / 1920);
      @include media($w1024) {
        margin: 0 0 30px;
      }
    }
  }
}
