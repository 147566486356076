.page-fleet {
  background-color: $lead;
  color: $white;
  padding: (190 * 100vw / 1920) 0 0;
  position: relative;
  @include media($w1024) {
    padding: 125px 0 0;
  }
  &__addon {
    width: (764 * 100vw / 1920);
    position: absolute;
    top: (-150 * 100vw / 1920);
    left: (360 * 100vw / 1920);
    @include media($w1024) {
      width: 80%;
      top: -50px;
      left: 10%;
    }
    @include media($w560) {
      width: calc(100% - 60px);
      left: 30px;
    }
    img {
      width: 100%;
    }
  }
}
