a {
	@include transition;
	text-decoration: none;
	color: $lead;
	&:link {
		text-decoration: none;
	}
	&:hover {
		text-decoration: none;
		color: $lead;
	}
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 500;
	margin: 0;
}

h1 {
	font-size: (121 * 100vw / 1920);
	line-height: (111 * 100vw / 1920);
	@include media($w1024) {
		font-size: 70px;
		line-height: 65px;
	}
	@include media($w768) {
		font-size: 60px;
		line-height: 55px;
	}
	@include media($w560) {
		font-size: 50px;
		line-height: 45px;
	}
}

h2 {
	font-size: (121 * 100vw / 1920);
	line-height: (111 * 100vw / 1920);
	@include media($w1024) {
		font-size: 70px;
		line-height: 65px;
	}
	@include media($w768) {
		font-size: 60px;
		line-height: 55px;
	}
	@include media($w560) {
		font-size: 50px;
		line-height: 45px;
	}
}

h3 {
	font-size: (55 * 100vw / 1920);
	line-height: (60 * 100vw / 1920);
	@include media($w1024) {
		font-size: 36px;
		line-height: 44px;
	}
}

h4 {
	font-size: (33 * 100vw / 1920);
	line-height: (43 * 100vw / 1920);
	@include media($w1024) {
		font-size: 26px;
		line-height: 32px;
	}
}

h5 {
	font-size: (27 * 100vw / 1920);
	line-height: (35 * 100vw / 1920);
	@include media($w1024) {
		font-size: 22px;
		line-height: 28px;
	}
}

h6 {
	font-size: (22 * 100vw / 1920);
	line-height: (29 * 100vw / 1920);
	@include media($w1024) {
		font-size: 18px;
		line-height: 24px;
	}
}

p {
	font-size: (22 * 100vw / 1920);
	line-height: (29 * 100vw / 1920);
	margin: 0;
	@include media($w1024) {
		font-size: 17px;
		line-height: 25px;
	}
}

ul {
	li {
		position: relative;
	}
}

strong, b {
	font-weight: 900;
}

.c {
	h2, h3, h4, h5, h6 {
		font-weight: 400;
	}
	h1, h2, h3, h4, h5, h6, p, ul, ol {
		margin: 0 0 (40 * 100vw / 1920);
		@include media($w1024) {
	    margin: 0 0 30px;
	  }
		@include media($w768) {
	    margin: 0 0 25px;
	  }
		@include media($w560) {
	    margin: 0 0 20px;
	  }
	}
	p {
		margin: 0 0 (40 * 100vw / 1920);
		@include media($w1024) {
	    margin: 0 0 30px;
	  }
		@include media($w768) {
	    margin: 0 0 25px;
	  }
		@include media($w560) {
	    margin: 0 0 20px;
	  }
	}
	ol, ul {
		margin: 0 0 (40 * 100vw / 1920);
		@include media($w1024) {
	    margin: 0 0 30px;
	  }
		@include media($w768) {
	    margin: 0 0 25px;
	  }
		@include media($w560) {
	    margin: 0 0 20px;
	  }
		li {
			font-size: (22 * 100vw / 1920);
			line-height: (29 * 100vw / 1920);
			@include media($w1024) {
				font-size: 17px;
				line-height: 25px;
			}
		}
	}
	ol {
		li {
			margin: 0 0 0 (35 * 100vw / 1920);
			padding: 0 0 0 (10 * 100vw / 1920);
			list-style: decimal;
			@include media($w1024) {
				margin: 0 0 0 20px;
				padding: 0 0 0 5px;
		  }
			ol {
				li {
					list-style: lower-latin;
					margin: 0 0 0 (25 * 100vw / 1920);
					@include media($w1024) {
						margin: 0 0 0 10px;
				  }
					&:first-child {
						margin: (30 * 100vw / 1920) 0 0 (25 * 100vw / 1920);
						@include media($w1024) {
							margin: 20px 0 0 10px;
					  }
					}
					ol {
						li {
							list-style: lower-roman;
							list-style: disc;
							&:first-child {
								margin: (25 * 100vw / 1920) 0 0 (25 * 100vw / 1920);
								@include media($w1024) {
									margin: 20px 0 0 10px;
							  }
							}
						}
					}
				}
			}
		}
	}
	ul {
		li {
			margin: 0 0 0 (35 * 100vw / 1920);
			padding: 0 0 0 (10 * 100vw / 1920);
			list-style: decimal;
			@include media($w1024) {
				margin: 0 0 0 20px;
				padding: 0 0 0 5px;
		  }
			&:before {
				border-radius: 50%;
				background-color: $green;
				width: (5 * 100vw / 1920);
				height: (5 * 100vw / 1920);
				display: block;
				position: absolute;
				top: (10 * 100vw / 1920);
				left: 0;
			}
			ol {
				li {
					margin: 0 0 0 (25 * 100vw / 1920);
					@include media($w1024) {
						margin: 0 0 0 10px;
				  }
					&:first-child {
						margin: (30 * 100vw / 1920) 0 0 (25 * 100vw / 1920);
						@include media($w1024) {
							margin: 20px 0 0 10px;
					  }
					}
					ol {
						li {
							&:first-child {
								margin: (25 * 100vw / 1920) 0 0 (25 * 100vw / 1920);
								@include media($w1024) {
									margin: 20px 0 0 10px;
							  }
							}
						}
					}
				}
			}
		}
	}
	& > * {
		&:last-child {
			margin: 0 !important;
		}
	}
}
