* {
	@include box-sizing;
	&:before,
	&:after {
		@include box-sizing;
		display: block;
	}
}

::-moz-selection {
	background: $lead;
	color: $white;
}

::selection {
	background: $lead;
	color: $white;
}

body {
	background-color: $bg;
	font-family: 'Futura PT' !important;
	font-weight: 400;
	font-size: (22 * 100vw / 1920);
	line-height: (30 * 100vw / 1920);
	color: $lead;
	margin: 0;
	padding: 0;
	@include media($w1024) {
		font-size: 16px;
		line-height: 22px;
	}
}

img {
	margin: 0;
	display: block;
	user-select: none;
}

/* --- [ global styles ] --- */

.wrapper {
	width: 100%;
	overflow: hidden;
}

.container {
	width: (1480 * 100vw / 1920);
	max-width: 100%;
	margin: 0 auto;
	padding: 0 (30 * 100vw / 1920);
	position: relative;
	@include media($w1024) {
		width: 100%;
		padding: 0 30px;
	}
}

.clearfix {
	&:after {
		clear: both;
		display: table;
	}
}
