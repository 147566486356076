.mixed {
  position: relative;
  &__inner {
    justify-content: flex-end;
    display: flex;
    @include media($w1024) {
      flex-wrap: wrap;
    }
  }
  &__copy {
    flex: 0 0 50%;
    min-height: (620 * 100vw / 1920);
    padding: (85 * 100vw / 1920) 0 (75 * 100vw / 1920) (120 * 100vw / 1920);
    align-items: center;
    display: flex;
    @include media($w1024) {
      border-bottom: 1px solid rgba($white,0.125);
      flex: 0 0 100%;
      min-height: auto;
      padding: 50px 0;
    }
  }
  &__image {
    background-size: cover;
    background-position: center center;
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    @include media($w1024) {
      width: 100%;
      height: 400px;
      position: relative;
      top: auto;
      left: auto;
    }
    @include media($w768) {
      height: 350px;
    }
    @include media($w560) {
      height: 300px;
    }
  }
  &--revers {
    .mixed {
      &__inner {
        justify-content: flex-start;
      }
      &__copy {
        padding: (85 * 100vw / 1920) (120 * 100vw / 1920) (75 * 100vw / 1920) 0;
        @include media($w1024) {
          padding: 50px 0;
        }
      }
      &__image {
        left: auto;
        right: 0;
        @include media($w1024) {
          right: auto;
        }
      }
    }
  }
  &--no-middle {
    .mixed {
      &__copy {
        padding: (40 * 100vw / 1920) (120 * 100vw / 1920) (75 * 100vw / 1920) 0;
        align-items: flex-start;
        @include media($w1024) {
          padding: 0 0 50px;
        }
      }
    }
  }
  &--white {
    background-color: $white;
  }
}
