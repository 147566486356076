.header {
  width: 100%;
  padding: (45 * 100vw / 1920) (105 * 100vw / 1920) 0 (60 * 100vw / 1920);
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
  position: absolute;
  z-index: 200;
  top: 0;
  left: 0;
  @include media($w1024) {
    padding: 25px 30px 0;
  }
  &__logo {
    img {
      width: (146 * 100vw / 1920);
      @include media($w1024) {
        width: 100px;
      }
    }
  }
  &__menu {
    margin: (-4 * 100vw / 1920) 0 0;
    @include media($w1024) {
      @include transition;
      background-color: $lead;
      width: 100%;
      height: 100vh;
      opacity: 0;
      pointer-events: none;
      margin: 0;
      align-items: center;
      display: flex;
      position: fixed;
      z-index: 100;
      top: -50px;
      left: 0;
    }
    ul {
      display: flex;
      @include media($w1024) {
        flex: 0 0 100%;
        flex-wrap: wrap;
      }
      li {
        line-height: 0;
        @include media($w1024) {
          flex: 0 0 100%;
          text-align: center;
        }
        & + li {
          margin: 0 0 0 (45 * 100vw / 1920);
          @include media($w1024) {
            margin: 15px 0 0;
          }
        }
        a {
          font-size: (26 * 100vw / 1920);
          line-height: (34 * 100vw / 1920);
          color: $white;
          position: relative;
          @include media($w1024) {
            font-size: 20px;
            line-height: 28px;
          }
          &:after {
            @include translate;
            content: '';
            background-image: url(../images/menu--addon.svg);
            background-size: cover;
            width: (56 * 100vw / 1920);
            height: (56 * 100vw / 1920);
            display: none;
            position: absolute;
            top: calc(50% + (2 * 100vw / 1920));
            @include media($w1024) {
              width: 40px;
              height: 40px;
              top: calc(50% + 2px);
            }
          }
        }
        &.current-menu-item {
          a {
            color: $green;
            &:after {
              display: block;
            }
          }
        }
      }
    }
    &--subpage {
      ul {
        li {
          a {
            color: $lead;
            @include media($w1024) {
              color: $white;
            }
          }
        }
      }
    }
    &.el-visible {
      opacity: 1;
      pointer-events: auto;
      top: 0;
    }
  }
  &__trigger {
    width: 24px;
    height: 16px;
    overflow: hidden;
    cursor: pointer;
    display: none;
    position: absolute;
    z-index: 300;
    top: 25px;
    right: 30px;
    @include media($w1024) {
      display: block;
    }
    span {
      @include transition;
      @include translateY;
      background-color: $white;
      width: 100%;
      height: 2px;
      position: absolute;
      left: 0;
      &:before,
      &:after {
        @include transition;
        content: '';
        background-color: $white;
        width: 100%;
        height: 2px;
        position: absolute;
        left: 0;
      }
      &:before {
        top: 7px;
      }
      &:after {
        bottom: 7px;
      }
    }
    &--subpage {
      span {
        background-color: $lead;
        &:before,
        &:after {
          background-color: $lead;
        }
      }
      &.el-visible {
        span {
          &:before,
          &:after {
            background-color: $white;
          }
        }
      }
    }
    &.el-visible {
      position: fixed;
      span {
        background-color: transparent;
        &:before {
          transform: rotate(45deg);
          top: 0;
        }
        &:after {
          transform: rotate(-45deg);
          bottom: 0;
        }
      }
    }
  }
}
