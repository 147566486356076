table {
	border-bottom: (1 * 100vw / 1920) solid rgba($lead,0.125);
	margin: 0;
	@include media($w1024) {
		border-bottom-width: 1px;
	}
	thead {
		tr {
			th {
				border-top: (1 * 100vw / 1920) solid rgba($lead,0.125);
				font-size: (22 * 100vw / 1920);
				font-weight: 700;
				line-height: (29 * 100vw / 1920);
				text-align: left;
				padding: (15 * 100vw / 1920) 0;
				@include media($w1024) {
					border-top-width: 1px;
					font-size: 17px;
					line-height: 25px;
					padding: 12px 0;
				}
			}
		}
	}
	tbody {
		tr {
			td {
				border-top: (1 * 100vw / 1920) solid rgba($lead,0.125);
				font-size: (22 * 100vw / 1920);
				font-weight: 400;
				line-height: (29 * 100vw / 1920);
				text-align: left;
				padding: (15 * 100vw / 1920) 0;
				@include media($w1024) {
					border-top-width: 1px;
					font-size: 17px;
					line-height: 25px;
					padding: 12px 0;
				}
			}
		}
	}
}
