@font-face {
	font-family: 'Futura PT';
	src: url('../fonts/FuturaPT-Book.woff2') format('woff2'),
			 url('../fonts/FuturaPT-Book.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Futura PT';
	src: url('../fonts/FuturaPT-BookObl.woff2') format('woff2'),
			 url('../fonts/FuturaPT-BookObl.woff') format('woff');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Futura PT';
	src: url('../fonts/FuturaPT-Medium.woff2') format('woff2'),
			 url('../fonts/FuturaPT-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Futura PT';
	src: url('../fonts/FuturaPT-MediumObl.woff2') format('woff2'),
			 url('../fonts/FuturaPT-MediumObl.woff') format('woff');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Futura PT';
	src: url('../fonts/FuturaPT-Demi.woff2') format('woff2'),
			 url('../fonts/FuturaPT-Demi.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Futura PT';
	src: url('../fonts/FuturaPT-DemiObl.woff2') format('woff2'),
			 url('../fonts/FuturaPT-DemiObl.woff') format('woff');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Futura PT';
	src: url('../fonts/FuturaPT-Heavy.woff2') format('woff2'),
			 url('../fonts/FuturaPT-Heavy.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Futura PT';
	src: url('../fonts/FuturaPT-HeavyObl.woff2') format('woff2'),
			 url('../fonts/FuturaPT-HeavyObl.woff') format('woff');
	font-weight: 900;
	font-style: italic;
}
