.page-fleet {
  .hero {
    position: relative;
    &__copy {
      width: 50%;
      min-height: (620 * 100vw / 1920);
      padding: (40 * 100vw / 1920) (60 * 100vw / 1920) 0 0;
      @include media($w1024) {
        width: 100% !important;
        min-height: auto !important;
        padding: 0 0 50px!important;
      }
      h1 {
        margin: 0 0 (60 * 100vw / 1920);
        @include media($w1024) {
          margin: 0 0 30px !important;
        }
      }
    }
    &__slider {
      width: 50%;
      height: (620 * 100vw / 1920);
      overflow: visible !important;
      position: absolute;
      top: 0;
      right: 0;
      @include media($w1024) {
        display: none;
      }
      .slider {
        &__slide {
          background-size: cover;
          background-position: center center;
        }
        &__navigation {
          border-bottom: (5 * 100vw / 1920) solid $white;
          width: (325 * 100vw / 1920);
          padding: 0 0 (30 * 100vw / 1920);
          display: flex;
          position: absolute;
          bottom: (70 * 100vw / 1920);
          right: calc(100% + (50 * 100vw / 1920));

          .navigation {
            &__prev,
            &__next {
              @include transition;
              flex: 0 0 50%;
              opacity: 0.4;
              padding: (10 * 100vw / 1920);
              img {
                width: (50 * 100vw / 1920);
                margin: 0 auto;
              }
              &:hover {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}
