.page-contact {
  background-color: $gray;
  padding: (190 * 100vw / 1920) 0 0;
  position: relative;
  @include media($w1024) {
    padding: 105px 0 0;
  }
  &__data {
    padding: 0 (90 * 100vw / 1920) 0 (210 * 100vw / 1920);
    position: relative;
    z-index: 50;
    @include media($w1024) {
      padding: 0;
    }
    .data {
      &__outer {
        @include transition;
        @include shadow;
        background-color: $white;
        padding: (60 * 100vw / 1920) (95 * 100vw / 1920) (80 * 100vw / 1920);
        position: relative;
        @include media($w1024) {
          padding: 40px 30px 50px;
        }
        h1 {
          margin: 0 0 (45 * 100vw / 1920);
          @include media($w1024) {
            margin: 0 0 30px;
          }
        }
        &.el-hidden {
          @include shadowReset;
        }
      }
      &__inner {
        flex-wrap: wrap;
        display: flex;
        .inner {
          &__left {
            flex: 0 0 28.5%;
            @include media($w1024) {
              flex: 0 0 100%;
              padding: 0 0 20px;
            }
            p {
              margin: 0 0 (20 * 100vw / 1920);
              @include media($w1024) {
                margin: 0 0 15px;
              }
            }
            .left {
              &__button {
                padding: (45 * 100vw / 1920) 0 0;
                @include media($w1024) {
                  padding: 10px 0 0;
                }
              }
            }
          }
          &__center {
            flex: 0 0 32%;
            @include media($w1024) {
              flex: 0 0 100%;
              flex-wrap: wrap;
              display: flex;
            }
            .center {
              &__item {
                margin: 0 0 (40 * 100vw / 1920);
                @include media($w1024) {
                  flex: 0 0 50%;
                  margin: 0 0 20px;
                  padding: 0 15px 0 0;
                }
                @include media($w560) {
                  flex: 0 0 100%;
                  padding: 0;
                }
                &:nth-child(2n+2) {
                  @include media($w1024) {
                    padding: 0 0 0 15px;
                  }
                  @include media($w560) {
                    padding: 0;
                  }
                }
                h4 {
                  font-weight: 400;
                  margin: 0 0 (20 * 100vw / 1920);
                  @include media($w1024) {
                    margin: 0 0 20px;
                  }
                }
              }
            }
          }
          &__right {
            flex: 0 0 39.5%;
            @include media($w1024) {
              flex: 0 0 100%;
            }
            h4 {
              font-weight: 400;
              margin: 0 0 (20 * 100vw / 1920);
              @include media($w1024) {
                margin: 0 0 20px;
              }
            }
            .right {
              &__body {
                margin: 0 0 (-35 * 100vw / 1920);
                flex-wrap: wrap;
                display: flex;
                .body {
                  &__item {
                    flex: 0 0 50%;
                    margin: 0 0 (35 * 100vw / 1920);
                    @include media($w1024) {
                      margin: 0 0 20px;
                      padding: 0 15px 0 0;
                    }
                    @include media($w560) {
                      flex: 0 0 100%;
                      padding: 0;
                    }
                    &:nth-child(2n+2) {
                      @include media($w1024) {
                        padding: 0 0 0 15px;
                      }
                      @include media($w560) {
                        padding: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      &__form {
        @include transition;
        @include shadow;
        background-color: $white;
        width: 100%;
        min-height: 100%;
        opacity: 0;
        pointer-events: none;
        padding: (60 * 100vw / 1920) (95 * 100vw / 1920) (80 * 100vw / 1920);
        position: absolute;
        z-index: 100;
        top: 0;
        left: 0;
        @include media($w1024) {
          padding: 40px 30px 50px;
        }
        h2 {
          margin: 0 0 (45 * 100vw / 1920);
          @include media($w1024) {
            margin: 0 0 30px;
            padding: 0 40px 0 0;
          }
        }
        .form {
          &__close {
            width: (46 * 100vw / 1920);
            cursor: pointer;
            position: absolute;
            top: (85 * 100vw / 1920);
            right: (85 * 100vw / 1920);
            @include media($w1024) {
              width: 24px;
              top: 30px;
              right: 30px;
            }
            img {
              width: 100%;
            }
          }
          &__body {
            flex-wrap: wrap;
            display: flex;
          }
          &__left {
            flex: 0 0 43%;
            @include media($w1024) {
              flex: 0 0 100%;
              padding: 0 0 5px;
            }
            p {
              margin: 0 0 (20 * 100vw / 1920);
              @include media($w1024) {
                margin: 0 0 15px;
              }
            }
          }
          &__right {
            flex: 0 0 57%;
            @include media($w1024) {
              flex: 0 0 100%;
            }
          }
        }
        &.el-visible {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }
  &__map {
    height: (745 * 100vw / 1920);
    position: relative;
    z-index: 25;
    @include media($w1024) {
      height: 450px;
    }
    @include media($w768) {
      height: 400px;
    }
    @include media($w560) {
      height: 350px;
    }
  }
}
