.page-reference {
  background-color: $gray;
  padding: (190 * 100vw / 1920) 0 0;
  position: relative;
  @include media($w1024) {
    padding: 125px 0 0;
  }
  &__inner {
    padding: 0 0 (200 * 100vw / 1920);
    position: relative;
    @include media($w1024) {
      padding: 0 0 60px;
    }
    @include media($w560) {
      padding: 0 0 50px;
    }
  }
  &__copy {
    margin: 0 0 (60 * 100vw / 1920);
    @include media($w1024) {
      margin: 0 0 42px;
    }
  }
  &__list {
    ul {
      width: calc(100% + (90 * 100vw / 1920));
      margin: 0 (-45 * 100vw / 1920) (-90 * 100vw / 1920);
      flex-wrap: wrap;
      display: flex;
      @include media($w1024) {
        width: calc(100% + 40px);
        margin: 0 -20px -40px;
      }
      @include media($w560) {
        width: calc(100% + 30px);
        margin: 0 -15px -30px;
      }
      li {
        margin: 0 (45 * 100vw / 1920) (90 * 100vw / 1920);
        @include media($w1024) {
          margin: 0 20px 40px;
        }
        @include media($w560) {
          margin: 0 15px 30px;
        }
        img {
          @include shadow;
          height: (282 * 100vw / 1920);
          @include media($w1024) {
            height: 200px;
          }
          @include media($w768) {
            height: 170px;
          }
          @include media($w560) {
            height: 140px;
          }
        }
      }
    }
  }
}
