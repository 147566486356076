.advantages {
  flex: 0 0 58.5%;
  margin: 0 0 (-50 * 100vw / 1920);
  flex-wrap: wrap;
  display: flex;
  @include media($w1024) {
    flex: 0 0 100%;
    margin: 0 0 -30px;
  }
  @include media($w1024) {
    margin: 0 0 -25px;
  }
  &__item {
    flex: 0 0 50%;
    margin: 0 0 (50 * 100vw / 1920);
    @include media($w1024) {
      margin: 0 0 30px;
    }
    @include media($w768) {
      padding: 0 10px 0 0;
    }
    @include media($w560) {
      margin: 0 0 25px;
    }
    &:nth-child(2n+2) {
      @include media($w768) {
        padding: 0 0 0 10px;
      }
    }
    figure {
      img {
        height: (62 * 100vw / 1920);
        margin: 0 0 (15 * 100vw / 1920);
        @include media($w1024) {
          height: 50px;
          margin: 0 0 10px;
        }
        @include media($w560) {
          height: 45px;
        }
      }
    }
    h6 {
      max-width: (320 * 100vw / 1920);
      font-weight: 400;
      @include media($w1024) {
        max-width: 80%;
      }
      @include media($w768) {
        max-width: 100%;
      }
    }
  }
  &--subpage {
    flex: 0 0 100%;
    margin: 0 0 (45 * 100vw / 1920);
    @include media($w1024) {
      margin: 0 0 20px;
    }
    @include media($w560) {
      margin: 0 0 15px;
    }
    .advantages {
      &__item {
        flex: 0 0 25%;
        margin: 0 0 (55 * 100vw / 1920);
        @include media($w1024) {
          flex: 0 0 calc(100% / 3);
          margin: 0 0 30px;
        }
        @include media($w768) {
          flex: 0 0 50%;
        }
        @include media($w560) {
          margin: 0 0 25px;
        }
      }
    }
  }
}
