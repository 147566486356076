.sidebar {
  border-right: (1 * 100vw / 1920) solid rgba($lead,0.15);
  width: (142 * 100vw / 1920);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  @include media($w1024) {
    display: none;
  }
  h6 {
    width: (400 * 100vw / 1920);
    font-size: (17 * 100vw / 1920);
    font-weight: 400;
    line-height: (22 * 100vw / 1920);
    text-transform: uppercase;
    letter-spacing: 0.2em;
    transform: rotate(90deg);
    transform-origin: bottom right;
    position: absolute;
    top: (505 * 100vw / 1920);
    left: (-340 * 100vw / 1920);
  }
  &--dark {
    border-right: (1 * 100vw / 1920) solid rgba($white,0.15);
  }
}
