.page-company {
  .hero {
    margin: 0 0 (110 * 100vw / 1920);
    position: relative;
    @include media($w1024) {
      margin: 0 0 50px;
    }
    &__copy {
      width: 50%;
      padding: (40 * 100vw / 1920) (60 * 100vw / 1920) (110 * 100vw / 1920) 0;
      @include media($w1024) {
        width: 100%;
        padding: 0 0 50px;
      }
      @include media($w560) {
        padding: 0 0 40px;
      }
      h1 {
        margin: 0 0 (60 * 100vw / 1920);
        @include media($w1024) {
          margin: 0 0 30px;
        }
      }
    }
    &__image {
      width: 50%;
      position: absolute;
      top: 0;
      right: 0;
      @include media($w1024) {
        display: none;
      }
      img {
        width: 100%;
      }
    }
  }
}
