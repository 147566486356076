.bottom {
  background-color: $lead;
  color: $white;
  padding: (130 * 100vw / 1920) 0 0;
  @include media($w1024) {
    padding: 35px 0 0;
  }
  @include media($w768) {
    padding: 45px 0 0;
  }
  a {
    color: $white;
  }
  &__inner {
    display: flex;
    @include media($w1024) {
      flex-wrap: wrap;
    }
  }
  &__left {
    border-right: (1 * 100vw / 1920) solid rgba($white,0.125);
    flex: 0 0 40%;
    padding: 0 (70 * 100vw / 1920) (20 * 100vw / 1920) 0;
    @include media($w1024) {
      border-right-width: 1px;
      flex: 0 0 50%;
      padding: 29px 30px 23px 0;
    }
    @include media($w768) {
      border-right: 0;
      flex: 0 0 100%;
      padding: 0 0 25px;
    }
    .left {
      &__logo {
        width: (100 * 100vw / 1920);
        margin: 0 0 (50 * 100vw / 1920);
        @include media($w1024) {
          width: 80px;
          margin: 0 0 30px;
        }
      }
    }
  }
  &__center {
    border-right: (1 * 100vw / 1920) solid rgba($white,0.125);
    flex: 0 0 49%;
    padding: 0 (90 * 100vw / 1920) (20 * 100vw / 1920);
    @include media($w1024) {
      border-right: 0;
      flex: 0 0 50%;
      padding: 23px 0 23px 30px;
    }
    @include media($w768) {
      flex: 0 0 100%;
      padding: 0 0 25px;
    }
    p {
      margin: 0 0 (20 * 100vw / 1920);
      @include media($w1024) {
        margin: 0 0 20px;
      }
    }
  }
  &__right {
    flex: 0 0 11%;
    padding: 0 0 (20 * 100vw / 1920) (60 * 100vw / 1920);
    @include media($w1024) {
      flex: 0 0 100%;
      padding: 0 0 23px;
    }
    .right {
      &__menu {
        ul {
          @include media($w1024) {
            display: flex;
          }
          li {
            line-height: 0;
            & + li {
              margin: (28 * 100vw / 1920) 0 0;
              @include media($w1024) {
                margin: 0 0 0 20px;
              }
            }
            a {
              font-size: (22 * 100vw / 1920);
            	line-height: (29 * 100vw / 1920);
              @include media($w1024) {
                font-size: 17px;
                line-height: 25px;
              }
            }
          }
        }
      }
    }
  }
}
