input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="file"],
input[type="url"],
textarea,
select {
	@include transition;
	border: 0 !important;
	border-bottom: (1 * 100vw / 1920) solid rgba($lead,0.15) !important;
	border-radius: 0 !important;
	background-color: transparent !important;
	max-width: 100% !important;
	height: (62 * 100vw / 1920) !important;
	font-family: 'Futura PT' !important;
	font-size: (22 * 100vw / 1920) !important;
	line-height: (62 * 100vw / 1920) !important;
	color: $lead !important;
	outline: none !important;
	margin: 0 !important;
	padding: 0 !important;
	box-shadow: none !important;
	-webkit-appearance: none !important;
	@include media($w1024) {
		border-bottom-width: 1px !important;
		height: 48px !important;
		font-size: 17px !important;
		line-height: 48px !important;
	}
	&:focus {
		border-color: rgba($lead,0.40) !important;
		background-color: transparent !important;
	}
}

textarea {
	min-height: (62 * 100vw / 1920) !important;
	line-height: (29 * 100vw / 1920) !important;
	padding: (16 * 100vw / 1920) 0 0 !important;
	resize: none !important;
	@include media($w1024) {
		min-height: 48px !important;
		line-height: 22px !important;
		padding: 12px 0 0 !important;
	}
}

::-webkit-input-placeholder {
	color: $lead !important;
	opacity: 1 !important;
}

:-moz-placeholder {
	color: $lead !important;
	opacity: 1 !important;
}

::-moz-placeholder {
	color: $lead !important;
	opacity: 1 !important;
}

:-ms-input-placeholder {
	color: $lead !important;
	opacity: 1 !important;
}

button {
	border: 0 !important;
	border-radius: 0 !important;
	background-color: transparent !important;
	cursor: pointer !important;
	outline: none !important;
	margin: 0 !important;
	padding: 0 !important;
	box-shadow: none !important;
	-webkit-appearance: none !important;
}
