.page-company {
  .licences {
    h2 {
      margin: 0 0 (70 * 100vw / 1920);
      @include media($w1024) {
        margin: 0 0 40px;
      }
      @include media($w560) {
        margin: 0 0 30px;
      }
    }
    &__list {
      width: calc(100% + (50 * 100vw / 1920));
      margin: 0 (-25 * 100vw / 1920) (-50 * 100vw / 1920);
      flex-wrap: wrap;
      display: flex;
      @include media($w1024) {
        width: calc(100% + 40px);
        margin: 0 -20px -40px;
      }
      @include media($w560) {
        width: calc(100% + 30px);
        margin: 0 -15px- 30px;
      }
      .list {
        &__item {
          margin: 0 (25 * 100vw / 1920) (50 * 100vw / 1920);
          @include media($w1024) {
            margin: 0 20px 40px;
          }
          @include media($w560) {
            margin: 0 15px 30px;
          }
          img {
            @include shadow;
            height: (282 * 100vw / 1920);
            @include media($w1024) {
              height: 200px;
            }
            @include media($w768) {
              height: 170px;
            }
            @include media($w560) {
              height: 140px;
            }
          }
        }
      }
    }
  }
}
