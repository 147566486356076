.footer {
  background-color: $lead;
  color: $white;
  padding: (30 * 100vw / 1920) 0 (55 * 100vw / 1920);
  @include media($w1024) {
    padding: 5px 0 35px;
  }
  a {
    color: $white;
  }
  &__inner {
    position: relative;
    &:before,
    &:after {
      content: '';
      background-color: rgba($white,0.125);
      width: (1 * 100vw / 1920);
      height: calc(100% + (85 * 100vw / 1920));
      position: absolute;
      top: (-30 * 100vw / 1920);
      @include media($w1024) {
        display: none;
      }
    }
    &:before {
      left: calc(40% - (1 * 100vw / 1920));
    }
    &:after {
      left: calc(89% - (1 * 100vw / 1920));
    }
  }
  &__copy {
    opacity: 0.5;
    p {
      font-weight: 500;
    }
  }
  &__author {
    opacity: 0.3;
  }
}
