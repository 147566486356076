.kv {
  background-color: $black;
  height: 100vh;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  position: relative;
  @include media($w768) {
    justify-content: flex-start;
  }
  @include media($w560) {
    height: 600px;
  }
  &__image {
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    opacity: 0.75;
    position: absolute;
    z-index: 25;
    top: 0;
    left: 0;
    @include media($w768) {
      opacity: 0.5;
    }
  }
  &__overlay {
    background-image: url(../images/kv--overlay.png);
    background-size: cover;
    background-position: center bottom;
    width: 100%;
    height: (420 * 100vw / 1920);
    pointer-events: none;
    position: absolute;
    z-index: 50;
    top: 0;
    left: 0;
    @include media($w1024) {
      height: 200px;
    }
  }
  &__map {
    width: (764 * 100vw / 1920);
    pointer-events: none;
    position: absolute;
    z-index: 75;
    bottom: (20 * 100vw / 1920);
    right: (-140 * 100vw / 1920);
    @include media($w1024) {
      width: 50%;
      bottom: 20px;
      right: 0;
    }
    @include media($w768) {
      width: 80%;
      bottom: 60px;
      right: -25%;
    }
    @include media($w560) {
      width: 100%;
    }
    img {
      width: 100%;
    }
  }
  &__copy,
  &__numbers {
    color: $white;
    position: relative;
    z-index: 100;
  }
  &__copy {
    padding: (40 * 100vw / 1920) (105 * 100vw / 1920) 0;
    @include media($w1024) {
      padding: 0 30px;
    }
    @include media($w560) {
      padding: 0 30px 130px;
    }
    h1 {
      margin: 0 0 (60 * 100vw / 1920);
      @include media($w1024) {
        margin: 0 0 30px;
      }
      @include media($w768) {
        margin: 0 0 25px;
      }
      @include media($w560) {
        margin: 0 0 20px;
      }
    }
  }
  &__numbers {
    width: 100%;
    padding: 0 0 (65 * 100vw / 1920) (90 * 100vw / 1920);
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    @include media($w1024) {
  		padding: 0 0 30px 30px;
  	}
    @include media($w560) {
      flex-wrap: wrap;
    }
    .numbers {
      &__item {
        flex: 0 0 (380 * 100vw / 1920);
        @include media($w1024) {
      		flex: 0 0 calc(100% / 3);
      	}
        @include media($w560) {
          flex: 0 0 100%;
        }
        & + .numbers {
          &__item {
            @include media($w560) {
              margin: 5px 0 0;
            }
          }
        }
        strong,
        span {
          display: block;
        }
        strong {
          font-size: (55 * 100vw / 1920);
          font-weight: 400;
          line-height: (71 * 100vw / 1920);
          @include media($w1024) {
        		font-size: 36px;
            line-height: 44px;
        	}
        }
        span {
          font-size: (26 * 100vw / 1920);
          line-height: (34 * 100vw / 1920);
          @include media($w1024) {
        		font-size: 16px;
            line-height: 24px;
        	}
        }
      }
    }
  }
}
