.cookies {
	background-color: $lead;
	width: 100%;
	min-height: (70 * 100vw / 1920);
	color: $white;
	padding: (20 * 100vw / 1920) (30 * 100vw / 1920);
	justify-content: space-between;
	align-items: center;
	display: none;
	position: fixed;
	z-index: 9999;
	bottom: 0;
	left: 0;
	@include media($w1024) {
		min-height: 50px;
		padding: 15px 30px;
	}
	@include media($w768) {
		flex-wrap: wrap;
		justify-content: center;
		align-items: flex-start;
	}
	p {
		@include media($w768) {
			width: 100%;
			flex: 0 0 100%;
			text-align: center;
			margin: 0 0 5px;
		}
		a {
			font-weight: 700;
			text-decoration: none;
			color: $white;
		}
	}
}
